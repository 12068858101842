<template>
  <b-modal
    id="m-Mensagens"
    size="lg"
    hide-footer
    no-close-on-backdrop
  >
    <template #modal-header="{ close }">
      <h5 class="mt-50">
        Mensagens da caixa postal
      </h5>
      <div class="float-right">
        <b-btn
          v-b-tooltip.hover
          title="Enviar link"
          variant="none"
          class="btn-none icone-acao my-0 mx-50 p-0"
          @click="enviarMensagem"
        >
          <feather-icon
            icon="SendIcon"
            class="text-primary"
            size="20"
          />
        </b-btn>
        <b-btn
          v-if="linha.temAnexo"
          v-b-tooltip.hover
          title="Anexos"
          variant="none"
          class="btn-none icone-acao m-0 p-0"
          style="padding: 0.4rem;"
          :disabled="!linha.anexos"
          @click="abrirAnexos()"
        >
          <feather-icon
            icon="PaperclipIcon"
            :class="'text-primary'"
            size="17"
          />
        </b-btn>
        <b-btn
          class="btn-outline-secondary my-0 mx-50 p-50 rounded"
          @click="close()"
        >
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </b-btn>
      </div>
    </template>
    <template #modal-title>
      Mensagens da caixa postal
    </template>
    <div
      v-if="linha"
      class="mensagem-tela"
      v-html="linha.mensagem"
    />
  </b-modal>
</template>

<script>
export default {
  props: {
    linha: {
      default: null,
      type: Object,
    },
  },
  methods: {
    enviarMensagem() {
      this.$emit('enviarMensagem')
    },
    abrirAnexos() {
      this.$emit('abrirAnexos')
    },
    abrirModal() {
      this.$refs.modalEnviarEmail.show()
    },
  },
}
</script>
